import {
    GET_COMMENTS_ERR, GET_COMMENTS_REQ, GET_COMMENTS_RES,
    CREATE_COMMENTS_ERR, CREATE_COMMENTS_REQ, CREATE_COMMENTS_RES,
} from '../../actions/types';



export default function (
    state = {
        comments: [],
        latest_comment: {},
        loading: false,
        error: null,
    },
    action = {}
) {
    switch (action.type) {
        case GET_COMMENTS_REQ:
        case CREATE_COMMENTS_REQ:
            return { ...state, loading: true };
        case GET_COMMENTS_RES:
            return { ...state, comments: action.comments, loading: false }
        case CREATE_COMMENTS_RES:
            return { ...state, latest_comment: action.latest_comment, loading: false }
        case CREATE_COMMENTS_ERR:
        case GET_COMMENTS_ERR:
            return { ...state, loading: false, error: action.err, comments: [], latest_comment: {} };
        default:
            return state;
    }
}
